// Include libraries
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import axios from "axios";
import VueCarousel from "vue-carousel";

// import WebFont from 'webfontloader'
// import MobileDetect from 'mobile-detect'

// Init Mobile Detect
// const md = new MobileDetect(window.navigator.userAgent)

// Init Google Fonts
// WebFont.load({
//   google: {
//     families: ['Open+Sans:300,400']
//   }
// })

// Vue use
Vue.use(VueCookies)
Vue.config.devtools=true

// Vue
const header = new Vue({
  el: '.header',
  methods: {
    changeCurrency: function (cur) {
      return;
      if (this.$cookies.isKey('currency')) {
        this.$cookies.remove('currency')
      }
      this.$cookies.set('currency', cur, '1y', '/') // expire at 1 year
      location.reload()
    },
    changeLanguage: function (currLanguage, language) {
      if (this.$cookies.isKey('language')) {
        this.$cookies.remove('language')
      }
      this.$cookies.set('language', language, '1y', '/');// expire at 1 year
      window.history.pushState('','',
        document.location.href.replace('/' + currLanguage + '/', '/' + language + '/'));
      let data = new FormData();
      data.append('language', language);
      axios.defaults.xsrfHeaderName = 'X-CSRFToken';
      axios.post('/__i18n__/setlang/', data, {
        headers: {
          'X-CSRFToken': this.$cookies.get('csrftoken'),
          'Content-Type': 'multipart/form-data'
        },
      }).then(() => location.reload())
    },
    openMobileMenuModal: function () {
      // Define
      const header_mobile_menu_modal = document.querySelector('.header__mobile_menu__modal')
      const header_mobile_menu_toggle = document.querySelector('.header__mobile_menu__toggle')

      // Toggle .is-active class
      header_mobile_menu_modal.classList.toggle('is-active')
      header_mobile_menu_toggle.classList.toggle('is-active')

      // Toggle .modal-opened class to body
      document.body.classList.toggle('modal-opened')
    }
  },
  mounted() {
    jQuery('[data-toggle="tooltip"]').tooltip();
  }
})

if (document.querySelectorAll('.Cookie__notify__container').length > 0) {
  setTimeout(() => {
    const cookieNotify = new Vue({
    el: '.Cookie__notify__container',
    data:  {
      hideNotification: true
    },
    methods: {
      confirmCookieNotification: function () {
        this.$cookies.set('cookieConfirm', true, '1y', '/');
        this.hideNotification = true;
      },
    },
    mounted(){
      this.hideNotification = this.$cookies.get('cookieConfirm') === 'true';

      //Auto set currency to Euro
      this.$cookies.remove('currency');
      this.$cookies.set('currency', 'euro', '1y', '/');
    }
  })
  } ,1000)

}

if (document.querySelectorAll('.Payment__container').length > 0) {
  Vue.use(VueCarousel);
  const vuePayment = new Vue({
    el: '.Payment__container',
    delimiters: ['[[', ']]'],
  });
}
